import qrcode from "../assets/qr-code.webp";
export default function Home() {
  return (
    <div className="container py-3 home">
      <div className="row d-flex justify-content-center ">
        <div className="col-lg-5 text-left px-4 py-3">
          <h1 className="bold700">
            Best Website Design and Development Agency
          </h1>
          <h2 className="sub-title">
            DigioGreen is well known for its quality work, innovation, and
            client satisfaction.
          </h2>
          <div class="d-grid gap-2 py-3 ">
            <button type="button" className="btn btn-lg btn-warning btn-block">
              Portfolio
            </button>
          </div>
        </div>
        <div className="col-lg-5 text-center px-4 py-3">
          <h4 className="bold700">We have worked for you?</h4>
          <img src={qrcode} className="img-fluid qrcode" alt="Review us" />
        </div>
      </div>
    </div>
  );
}
