export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-9  px-4">
            <hr />
            <h3>
              Want your business online? Let us help you, mail us or call us now
              to consult.
            </h3>
            <a
              className="App-link"
              href="mailto:hello@digiogreen.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> hello@digiogreen.com</strong>
            </a>
            &nbsp; |&nbsp;
            <a
              className="App-link"
              href="tel:919415869983"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> +91-9415869983</strong>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
